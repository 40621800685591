<template>
  <div>
    <b-modal modalClass="offer-creator-modal" centered :hideHeader="true" hide-footer id="new-offer-modal" size="lg">
      <div class="lg:tlw-px-6 lg:tlw-py-10">
        <hs-button @click="$bvModal.hide('new-offer-modal')" variant="link" class="tlw-absolute tlw-top-0 tlw-right-0">
          <hs-icon class="tlw-text-black" icon="times" />
        </hs-button>
        <Offer :isSalesPageModal="isSalesPageModal" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Offer from './Offers/index';

export default {
  components: { Offer },
  data() {
    return {
      isSalesPageModal: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: $screen-sm) {
  /deep/ .offer-creator-modal {
    .modal-dialog {
      margin: 0;
      width: 100%;
      max-width: inherit;
      position: relative;
    }
    .modal-dialog-centered {
      align-items: flex-end;
      height: 100vh;
    }
    .modal-content {
      border-radius: 0;
      border: 0;
    }
    .modal-body {
      padding: 1rem;
      position: relative;
      max-height: 90vh;
      overflow-y: auto;
    }
    .card-img {
      display: none;
    }
    .card-body {
      padding: 1rem;
      position: relative;

      &::before {
        content: ' ';
        height: 10px;
        background-color: #bcfbff;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0px;
      }
    }
  }
}
</style>
